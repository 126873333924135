<template>
  <div class="advanced-search">
    <WAutocomplete
      v-model="selectedPrompt"
      :items="allPrompts"
      @change="applyPrompt"
      label="Modèles de prompt"
    />

    <v-textarea
      class="pa-3"
      v-model="prompt"
      hide-details
    />

    <v-row no-gutters>
      <v-col class="text-right py-0 my-1 px-3">
        <v-btn
          @click="search"
          :disabled="prompt.length == 0"
          :ripple="false"
          :loading="searching"
          color="secondary"
        >
          <v-icon left dark>mdi-robot-happy-outline</v-icon>
          Demander à l'IA
        </v-btn>
      </v-col>
    </v-row>

    <div class="pa-3">  
      <v-alert
        class="f-14"
        color="primary"
        border="left"
        elevation="2"
        colored-border
        icon="mdi-robot-happy"
      >
        <div v-for="resultLine in resultLines">
          {{ resultLine }}  
        </div>
        <div v-if="cost">
          (cost: {{ cost }}$)  
        </div>
        
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvandedSearch",
  props: [ 'filteredVoterRequestBase' ],
  data() {
    return {
      prompt: "",
      cost: null,
      searching: false,
      advandedPrompt: false,
      selectedPrompt: null,
      resultLines: ["Bonjour, je suis là pour vous aider à analayser vos avis, je suis limité à 100 avis pour l'instant"],
      allPrompts: [
        { text: "Fais moi un résumé", value: "Fais moi un résumé en trois points" },
        { text: "Donne moi les motifs d'insatifaction", value: "Donne moi les motifs d'insatifaction en trois points" },
      ]
    }
  },
  methods: {
    applyPrompt(value) {
      this.prompt = value
    },
    async search() {
      this.searching = true
      const promptedVoterRequest = this.filteredVoterRequestBase.limit(100).select({
        voters: [
          { prompted: { batch_params: { prompt: this.prompt } } }
        ]
      })

      const result = (await this.$resolve(promptedVoterRequest)).data.voters[0]
      const prompted = result.prompted
      const content = prompted.content
      const cost = prompted.cost
      
      this.resultLines = content.split("\n")
      this.cost = cost
      this.searching = false
    }
  }
}
</script>
